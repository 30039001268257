import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";

import VideoImage from "../images/video_city.jpg"

const VideoPage = props => {

  const data = 1;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="My Videos" theme={theme} />
            </header>
            <video style={{'width': '100%'}} height="450" controls poster={VideoImage}>
              <source src="http://ucxp.com/hudson_river_3.mp4" type="video/mp4" />
            </video>
          </Article>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

VideoPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default VideoPage;

